import { providers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";

export async function SignerTrustWallet() {
  const provider = new WalletConnectProvider({
    rpc: {
      // 56: "https://bsc-dataseed.binance.org"
      4: "https://eth-rinkeby.alchemyapi.io/v2/AU17SnpmYQV9X9uamwXOnup_dW3YZxTG"
    },
  });

  //  Enable session (triggers QR Code modal)
  await provider.enable();

  const web3Provider = new providers.Web3Provider(provider);

  const signer = web3Provider.getSigner();

  return signer;
}