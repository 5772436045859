import { ethers, providers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import abi from 'contract/presale.json';

export async function ContractTrustWallet() {
  const contractAddress = '0xBF8A5387EF5bbB56e721b40380A4e9c6933ce106';

  const provider = new WalletConnectProvider({
    rpc: {
      // 56: "https://bsc-dataseed.binance.org"
      4: "https://eth-rinkeby.alchemyapi.io/v2/AU17SnpmYQV9X9uamwXOnup_dW3YZxTG"
    },
  });

  await provider.enable();

  const web3Provider = new providers.Web3Provider(provider);
  const signer = web3Provider.getSigner();

  const Contract = new ethers.Contract(
    contractAddress,
    abi,
    signer
  )

  return Contract;
}