import { ethers } from "ethers";
import abi from 'contract/presale.json';

export async function ReadContract() {
  const contractAddress = '0xBF8A5387EF5bbB56e721b40380A4e9c6933ce106';
  const provider = ethers.getDefaultProvider('https://eth-rinkeby.alchemyapi.io/v2/AU17SnpmYQV9X9uamwXOnup_dW3YZxTG');

  const Contract = new ethers.Contract(
    contractAddress,
    abi,
    provider
  );

  return Contract;
}