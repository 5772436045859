import { useContext, useEffect } from "react"
import { ethers } from "ethers"
import { Context } from "context/contex"
import { SortOption, SortSelect } from "./styled"
import bnb from 'assets/bnb.png'
import busd from 'assets/busd.png'
import usdt from 'assets/usdt.png'
import dai from 'assets/dai.png'
import eth from 'assets/eth.png'
import { ErrorHandling } from "utils/errorHandling"
// import { Contract } from "utils/useContract"
import { ReadContract } from "utils/readContract"

export default function SelectToken() {
  const { setSelectedToken, setSelectedTokenPrice, setLoading } = useContext(Context);
  const supportedTokens = [
    {
      tokenAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", // USDC
      priceFeed: "0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6" // USDC/USD
    },
    {
      tokenAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7", // USDT
      priceFeed: "0x3E7d1eAB13ad0104d2750B8863b489D65364e32D" // USDT/USD
    },
    {
      tokenAddress: "0x6b175474e89094c44da98b954eedeac495271d0f", // DAI
      priceFeed: "0xAed0c38402a5d19df6E4c03F4E2DceD6e29c1ee9" // DAI/USD
    },
    // {
    //   tokenAddress: "0x2170ed0880ac9a755fd29b2688956bd959f933f8", // ETH
    //   priceFeed: "0x9ef1B8c0E4F7dc8bF5719Ea496883DC6401d5b2e" // ETH/USD
    // }
  ]

  const handleSelectToken = async(value) => {
    setSelectedToken(value);
    try {
      setLoading(true);
      const contract = await ReadContract();
  
      if(value === 'eth') {
        const data = await contract.getPrice();
        console.log(ethers.utils.formatUnits(data, 8))
        const price = Number(ethers.utils.formatUnits(data, 8));
        setSelectedTokenPrice(price);
        setLoading(false);
      } else {
        const data = await contract.getPriceToken(value);
        console.log(ethers.utils.formatUnits(data, 8))
        const price = Number(ethers.utils.formatUnits(data, 8));
        setSelectedTokenPrice(price);
        setLoading(false);
      }
    } catch (error) {
      ErrorHandling(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    handleSelectToken('eth');
  }, [])

  return (
    <SortSelect 
      placeholder="Select a token"
      defaultValue="eth"
      style={{ width: '40%' }}
      dropdownStyle={{
        color: '#fff',
        backgroundColor: '#1c274f',
      }}
      onChange={handleSelectToken}
    >
      <SortOption value="eth">
        <img src={eth} alt='eth' width='24' />
        <span style={{marginLeft: '10px', color: '#fff'}}>ETH</span>
      </SortOption>
      <SortOption value={supportedTokens[0].tokenAddress}>
        <img src={busd} alt='eth' width='24' />
        <span style={{marginLeft: '10px', color: '#fff'}}>USDC</span>
      </SortOption>
      <SortOption value={supportedTokens[1].tokenAddress}>
        <img src={usdt} alt='eth' width='24' />
        <span style={{marginLeft: '10px', color: '#fff'}}>USDT</span>
      </SortOption>
      <SortOption value={supportedTokens[2].tokenAddress}>
        <img src={dai} alt='eth' width='24' />
        <span style={{marginLeft: '10px', color: '#fff'}}>DAI</span>
      </SortOption>
      {/* <SortOption value={supportedTokens[3].tokenAddress}>
        <img src={eth} alt='eth' width='24' />
        <span style={{marginLeft: '10px', color: '#fff'}}>ETH</span>
      </SortOption> */}
    </SortSelect>
  )
}
